<template>
  <div class="not-found">
    <div>
      <router-link to="/dashboard">
        <el-button type="primary" round>返回首页</el-button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.not-found {
}
</style>
